.landing-page {
    color: rgb(54, 69, 79); /* Charcoal gray */
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Merriweather', serif;
    font-size: 2em;
    padding: 2em;
    text-align: center;
    position: relative; /* Relative position to set scroll-indicator at the bottom */
}

.landing-page .typed-text {
    letter-spacing: 1.2px;
    font-size: 3em;
    cursor: default; /* This will remove the text selection cursor */
}

.scroll-indicator {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 3em;
    color: rgb(54, 69, 79);
    cursor: pointer;
    animation: bounce 2s infinite;
}

/* Animation for the scroll indicator */
@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {transform: translateX(-50%) translateY(0);}
    40% {transform: translateX(-50%) translateY(-30px);}
    60% {transform: translateX(-50%) translateY(-15px);}
}

/* Responsive styling for screen widths 768px and below */
@media screen and (max-width: 768px) {
    .landing-page {
        font-size: 1.5em; /* Decrease font size for smaller screens */
        padding: 1em; /* Decrease padding for smaller screens */
        height: 80vh;
    }

    .landing-page .typed-text {
        font-size: 2em; /* Decrease font size for smaller screens */
    }

    .scroll-indicator {
        position: absolute;
        bottom: 100px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 3em;
        color: rgb(54, 69, 79);
        cursor: pointer;
        animation: bounce 2s infinite;
    }
}
