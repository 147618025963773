.about-section {
    background: linear-gradient(to right, #F7F7F8, #E3E3E3);
    color: rgb(54, 69, 79); /* Charcoal gray */
    font-family: 'Merriweather', serif;
    line-height: 1.8;
    padding: 6em 2em; /* Increased padding for more space */
    text-align: center;
}

.about-section h2 {
    font-size: 2.5em;
    letter-spacing: 1px;
}

.about-section p {
    font-size: 1.2em;
    letter-spacing: 0.5px;
}

/* Responsive styling for screen widths 768px and below */
@media screen and (max-width: 768px) {
    .about-section {
        padding: 3em 1em; /* Decrease padding for smaller screens */
    }

    .about-section h2 {
        font-size: 2em; /* Decrease font size for smaller screens */
    }

    .about-section p {
        font-size: 1em; /* Decrease font size for smaller screens */
    }

    /* Add other mobile-specific styles here */
}
