.showcase-video-section {
    background-color: rgb(247, 247, 248);
    padding: 4em 2em;
    color: rgb(54, 69, 79);
    font-family: 'Merriweather', serif;
    line-height: 1.8;
}

.showcase-video-section h2 {
    color: rgb(54, 69, 79);
    font-size: 2em;
    text-align: center;
    margin-bottom: 1em;
}

.video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
    height: 0;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 768px) {
    .showcase-video-section h2 {
        font-size: 1.5em;
    }
}
