.calendly-section {
    /*background: linear-gradient(to right, #F7F7F8, #E3E3E3);*/
    padding: 4em 2em;
    color: rgb(54, 69, 79); /* Charcoal gray */
    font-family: 'Merriweather', serif;
    line-height: 1.8;
    text-align: center;
}

.calendly-section h2 {
    font-size: 2.5em;
    margin-bottom: 1em;
}

/* Responsive styling for screen widths 768px and below */
@media screen and (max-width: 768px) {
    .calendly-section {
        padding: 2em 1em; /* Decrease padding for smaller screens */
    }

    .calendly-section h2 {
        font-size: 2em; /* Decrease font size for smaller screens */
    }

    /* Add other mobile-specific styles here */
}
